
/* Navbar.css */

.search-box {
  position: relative;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%; /* Adjusted width to match the search box */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%; /* Position the results below the search box */
  left: 0;
  border-radius: 4px;
  overflow: hidden; /* Hide any overflowing content */
}

.search-results li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.search-results a {
  text-decoration: none;
  color: #333;
  display: block;
}

.search-results a:hover {
  background-color: #f4f4f4;
}

/* Add this to your existing CSS file or create a new one */

.popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 500px; /* Adjust the width as needed */
  text-align: center;
}

.popup-box p {
  margin-bottom: 15px;
}

.popup-box button {
  background-image: linear-gradient(#42A1EC, #0070C9);
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.popup-box button:hover{
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;

}
.popup-header{
  text-align: end !important;
}






 .alan2{
    -webkit-filter: invert();
            filter: invert();
    height: 50px;
    margin-top: 25px;
    margin-left: 45px;
   
}
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 75px;
}
.arrow2{
    border: solid rgb(110, 66, 229);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 12px;

}
.supersave{
    color: rgb(7, 121, 76);
    font-family: os_semi_bold;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    
}
/* for horizontal line */
.dashed-line {
    border: 1px dashed gray;
  }
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.left{
    transform: rotate(45deg);
    -webkit-transform: rotate(-45deg);

}

.locationImage{
    height: 22px;
}
.box3{
    margin-top: 30px;
    border: 1px solid black;
    padding: 10px;
    /* width: 60%; */
    border-radius: 4px;
}
.search-box{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.search-box input{
    width: 100%;
    margin-left: 7px;
    border: none;
   
}
.searchImage{
    height: 15px;
}
.box4{
    margin-top: 30px;
    border: 1px solid black;
    padding: 10px;
    width: 60%;
    border-radius: 4px;
}

.RepairImages2{
    height: 50px;
    object-fit: cover;
    width: 50px;
}

/* shopping-cart */
.shopping-cart{
    height: 50px;
}

.cardbox{
    padding: 25px;
    border-radius: 4px;
} 

