 .alan2{
    filter: invert();
    height: 50px;
    margin-top: 25px;
    margin-left: 45px;
   
}
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 75px;
}
.arrow2{
    border: solid rgb(110, 66, 229);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 12px;

}
.supersave{
    color: rgb(7, 121, 76);
    font-family: os_semi_bold;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    
}
/* for horizontal line */
.dashed-line {
    border: 1px dashed gray;
  }
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.left{
    transform: rotate(45deg);
    -webkit-transform: rotate(-45deg);

}

.locationImage{
    height: 22px;
}
.box3{
    margin-top: 30px;
    border: 1px solid black;
    padding: 10px;
    /* width: 60%; */
    border-radius: 4px;
}
.search-box{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.search-box input{
    width: 100%;
    margin-left: 7px;
    border: none;
   
}
.searchImage{
    height: 15px;
}
.box4{
    margin-top: 30px;
    border: 1px solid black;
    padding: 10px;
    width: 60%;
    border-radius: 4px;
}

.RepairImages2{
    height: 50px;
    object-fit: cover;
    width: 50px;
}

/* shopping-cart */
.shopping-cart{
    height: 50px;
}

.cardbox{
    padding: 25px;
    border-radius: 4px;
} 
